import React, { useState } from "react";
import "./portfolio.css";
import HTML5 from "./svg/html5.svg";
import CSS3 from "./svg/css3.svg";
import BS from "./svg/bootstrap.svg";
import JS from "./svg/javascript.svg";
import Reacts from "./svg/react.svg";
import Expresss from "./svg/express.svg";
import Jquery from "./svg/jquery.svg";
import Ethereum from "./svg/ethereum.svg";
import Solidity from "./svg/Solidity.svg";
import VSCode from "./svg/vscode.svg";
import Git from "./svg/git.svg";
import Uparrow from "./svg/up-arrow.svg";
import Downarrow from "./svg/down-arrow.svg";
import C from "./svg/c.svg";
import Python from "./svg/python.svg";
import Responsive from "./svg/responsive.svg";
import DS from "./svg/datastructure.svg";
import TS from "./svg/typescript.svg";
import NJS from "./svg/nodejs.svg";
import API from "./svg/API.svg";
import RS from "./svg/reduxsaga.svg";
import Github from "./svg/github.svg";
import SC from "./svg/smartcontracts.svg";
import TW from "./svg/tailwind.svg";
import SCS from "./svg/styled-components.svg";
import SCSS from "./svg/scss.svg";
import Context from "./svg/context.svg";
import GenAI from "./svg/gen-ai.svg";
import GCP1 from "./svg/gcp1.svg";
import JEST from "./svg/jest.svg";
import VITEST from "./svg/vitest.svg";
import Octopus from "./svg/octopus.svg";
import Axios from "./svg/axios.svg";
import Fetch from "./svg/fetch.svg";

const Accordion = () => {
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);

  function onclick1() {
    setIsActive(!isActive);
    setIsActive1(false);
    setIsActive2(false);
  }

  function onclick2() {
    setIsActive1(!isActive1);
    setIsActive(false);
    setIsActive2(false);
  }

  function onclick3() {
    setIsActive2(!isActive2);
    setIsActive(false);
    setIsActive1(false);
  }

  return (
    <div className="accordion-item">
      <div
        className={isActive ? "accordion-titleact" : "accordion-title"}
        onClick={onclick1}
      >
        <div className="at_title">Technical Skills</div>
        <div className="plusminus">
          {isActive ? (
            <img src={Uparrow} alt="Minusicon" className="plusminus1" />
          ) : (
            <img src={Downarrow} alt="Plusicon" className="plusminus1" />
          )}
        </div>
      </div>
      <div className={`aniscroll ${isActive ? "show1" : ""}`}>
        <div id="animation" className={`accordion-content2`}>
          <div className="accdesbox1">
            <div className="frontend">
              <h2 className="ss">Front End:</h2>
            </div>
            <div className="frontenddes">
              <div className="skillcard">
                <section className="sc_imgg1">
                  <img src={HTML5} alt="HTML5" className="Html5pic" />
                </section>
                <section className="sc_text">HTML5</section>
              </div>
              <div className="skillcard">
                <section className="sc_imgg2">
                  <img src={CSS3} alt="CSS3" className="CSS3pic" />
                </section>
                <section className="sc_text">CSS3</section>
              </div>
              <div className="skillcard1">
                <section className="sc_imgg33">
                  <img src={SCSS} alt="Bootstrap" className="BSpic" />
                </section>
                <section className="sc_text">SCSS</section>
              </div>
              <div className="skillcard11">
                <section className="sc_imgg912">
                  <img src={TW} alt="Tailwind CSS" className="Twpic" />
                </section>
                <section className="sc_text4">Tailwind CSS</section>
              </div>
              <div className="skillcard112">
                <section className="sc_imgg121">
                  <img src={SCS} alt="Styled Components" className="SCSpic" />
                </section>
                <section className="sc_text4">Styled Components</section>
              </div>
              <div className="skillcard4">
                <section className="sc_imgg31">
                  <img src={Responsive} alt="Responsive" className="RSpic" />
                </section>
                <section className="sc_text4">Responsive Design</section>
              </div>
            </div>
          </div>
          <div className="accdesbox1">
            <div className="frontend">
              <h2 className="ss">Library & Framework:</h2>
            </div>
            <div className="frontenddes">
              <div className="skillcard2">
                <section className="sc_imgg8">
                  <img src={Reacts} alt="React" className="Reactpic" />
                </section>
                <section className="sc_text1">React JS</section>
              </div>
              <div className="skillcard1">
                <section className="sc_imgg4">
                  <img src={JS} alt="Javascript" className="JSpic" />
                </section>
                <section className="sc_text1">JavaScript</section>
              </div>
              <div className="skillcard1">
                <section className="sc_imgg101">
                  <img src={TS} alt="Typescript" className="tspic" />
                </section>
                <section className="sc_text3">TypeScript</section>
              </div>
              <div className="skillcard1">
                <section className="sc_imgg9">
                  <img src={Expresss} alt="Express" className="Expresspic" />
                </section>
                <section className="sc_text3">Express JS</section>
              </div>
              <div className="skillcard5">
                <section className="sc_imgg91">
                  <img src={RS} alt="Reduxsaga" className="Rspic" />
                </section>
                <section className="sc_text4">Redux-Saga</section>
              </div>
              {/* <div className="skillcard1">
                <section className="sc_imgg3">
                  <img src={BS} alt="Bootstrap" className="BSpic" />
                </section>
                <section className="sc_text">Bootstrap</section>
              </div> */}
            </div>
          </div>
          <div className="accdesbox1">
            <div className="frontend">
              <h2 className="ss">APIs:</h2>
            </div>
            <div className="frontenddes">
              <div className="skillcard1">
                <section className="sc_imgg1n">
                  <img src={API} alt="Rest API" className="apip" />
                </section>
                <section className="sc_text3">Rest API</section>
              </div>
              <div className="skillcard44">
                <section className="sc_imgg445">
                  <img src={Context} alt="Context API" className="apip" />
                </section>
                <section className="sc_text44">Context API</section>
              </div>
              <div className="skillcard">
                <section className="sc_imgg17">
                  <img src={Axios} alt="Context API" className="apipsp" />
                </section>
                <section className="sc_text3">Axios</section>
              </div>
              <div className="skillcard44">
                <section className="sc_imgg445">
                  <img src={Fetch} alt="Context API" className="apip" />
                </section>
                <section className="sc_text44">Fetch API</section>
              </div>
            </div>
          </div>
          <div className="accdesbox1">
            <div className="frontend">
              <h2 className="ss">Testing Tools:</h2>
            </div>
            <div className="frontenddes">
              <div className="skillcard">
                <section className="sc_imgg17">
                  <img src={JEST} alt="Git" className="Gitpic" />
                </section>
                <section className="sc_text">Jest</section>
              </div>
              <div className="skillcardsp">
                <section className="sc_imgg171">
                  <img src={Octopus} alt="GitHub" className="Gitpic" />
                </section>
                <section className="sc_text5">React Testing Library</section>
              </div>
            </div>
          </div>
          <div className="accdesbox1">
            <div className="frontend">
              <h2 className="ss">Version Control:</h2>
            </div>
            <div className="frontenddes">
              <div className="skillcard">
                <section className="sc_imgg17">
                  <img src={Git} alt="Git" className="Gitpic" />
                </section>
                <section className="sc_text">Git</section>
              </div>
              <div className="skillcard">
                <section className="sc_imgg171">
                  <img src={Github} alt="GitHub" className="Gitpic" />
                </section>
                <section className="sc_text">GitHub</section>
              </div>
            </div>
          </div>
          <div className="accdesbox1">
            <div className="frontend">
              <h2 className="ss">Platform:</h2>
            </div>
            <div className="frontenddes">
              <div className="skillcard1">
                <section className="sc_imgg13">
                  <img src={VSCode} alt="VSCode" className="VSCodepic" />
                </section>
                <section className="sc_text1">VS Code</section>
              </div>
            </div>
          </div>
          <div className="accdesbox1">
            <div className="frontend">
              <h2 className="ss">Other Skills:</h2>
            </div>
            <div className="frontenddes">
              <div className="skillcard1">
                <section className="sc_imgg1m">
                  <img src={NJS} alt="NodeJS" className="NJS" />
                </section>
                <section className="sc_text3">Node JS</section>
              </div>
              <div className="skillcard5">
                <section className="sc_imgg44">
                  <img src={DS} alt="Datastructures" className="DSpic" />
                </section>
                <section className="sc_text4">Data Structures</section>
              </div>
              <div className="skillcard2">
                <section className="sc_imgg2">
                  <img src={Python} alt="python" className="py" />
                </section>
                <section className="sc_text">Python3</section>
              </div>
              <div className="skillcard11">
                <section className="sc_imgg11">
                  <img src={Ethereum} alt="Ethereum" className="Ethereumpic" />
                </section>
                <section className="sc_text">Blockchain</section>
              </div>
              <div className="skillcard5">
                <section className="sc_imgg1mm">
                  <img src={GenAI} alt="NodeJS" className="GAI" />
                </section>
                <section className="sc_text4">Geneartive AI</section>
              </div>
              <div className="skillcard33">
                <section className="sc_imgg1nb">
                  <img src={GCP1} alt="Google Cloud" className="apip" />
                </section>
                <section className="sc_text4">Google Cloud</section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={isActive1 ? "accordion-titleact1" : "accordion-title"}
        onClick={onclick2}
      >
        <div className="at_title">Language Skills</div>

        <div className="plusminus">
          {isActive1 ? (
            <img src={Uparrow} alt="Minusicon" className="plusminus1" />
          ) : (
            <img src={Downarrow} alt="Plusicon" className="plusminus1" />
          )}
        </div>
      </div>
      <div className={`aniscroll1 ${isActive1 ? "show1" : ""}`}>
        <div className="accordion-content1">
          <div className="accdesbox">
            <div className="languages">
              <div className="langv1">
                <h1 className="lang1">English:</h1>
              </div>

              <div class="progressbar-wrapper lang2">
                <ul class="progressbar">
                  <li class="active">Read</li>
                  <li class="active">Write</li>
                  <li class="active">Speak</li>
                  <li class="active">Listen</li>
                </ul>
              </div>

              <div className="langv3">
                <h2>[Advanced]</h2>
              </div>
            </div>

            <div className="languages">
              <div className="langv1">
                <h1 className="lang1">German:</h1>
              </div>

              <div class="progressbar-wrapper lang2">
                <ul class="progressbar">
                  <li class="active">Read</li>
                  <li class="active">Write</li>
                  <li class="active">Speak</li>
                  <li class="active">Listen</li>
                </ul>
              </div>

              <div className="langv3">
                <h2>[Intermediate - B1 Level]</h2>
              </div>
            </div>

            <div className="languages">
              <div className="langv1">
                <h1 className="lang1">Tamil:</h1>
              </div>

              <div class="progressbar-wrapper lang2">
                <ul class="progressbar">
                  <li class="active">Read</li>

                  <li class="active">Write</li>

                  <li class="active">Speak</li>

                  <li class="active">Listen</li>
                </ul>
              </div>

              <div className="langv3">
                <h2>[Native Speaker]</h2>
              </div>
            </div>

            {/* <div className="languages">
              <div className="langv1">
                <h1 className="lang1">Malayalam:</h1>
              </div>

              <div class="progressbar-wrapper lang2">
                <ul class="progressbar">
                  <li>Read</li>

                  <li>Write</li>

                  <li class="active">Speak</li>

                  <li class="active">Listen</li>
                </ul>
              </div>

              <div className="langv3">
                <h2>[Elementary]</h2>
              </div>
            </div> */}
          </div>
          <div className="accdesbox mqhidden">
            <div className="accdesbox2">
              <div className="ips_box2">
                <div className="ips_box_title">
                  <h1>English: Advanced</h1>
                </div>
              </div>

              <div className="ips_box3">
                <div className="ips_box_title">
                  <h1>German: B1 Level</h1>
                </div>
              </div>
              <div className="ips_box2">
                <div className="ips_box_title">
                  <h1>Tamil: Native Speaker</h1>
                </div>
              </div>

              {/* <div className="ips_box4">
                <div className="ips_box_title">
                  <h1>Malayalam: Elementary</h1>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={isActive2 ? "accordion-titleact2" : "accordion-title"}
        onClick={onclick3}
      >
        <div className="at_title">Interpersonal Skills</div>

        <div className="plusminus">
          {isActive2 ? (
            <img src={Uparrow} alt="Minusicon" className="plusminus1" />
          ) : (
            <img src={Downarrow} alt="Plusicon" className="plusminus1" />
          )}
        </div>
      </div>
      <div className={`aniscroll2 ${isActive2 ? "show1" : ""}`}>
        <div className="accordion-content">
          <div className="accdesbox2">
            <div className="ips_box2">
              <div className="ips_box_title">
                <h1>Planning</h1>
              </div>
            </div>

            <div className="ips_box3">
              <div className="ips_box_title">
                <h1>Leadership</h1>
              </div>
            </div>
            <div className="ips_box2">
              <div className="ips_box_title">
                <h1>Management</h1>
              </div>
            </div>

            <div className="ips_box4">
              <div className="ips_box_title">
                <h1>Responsibility</h1>
              </div>
            </div>

            <div className="ips_box5">
              <div className="ips_box_title">
                <h1>Problem Solving</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
